<template>
  <v-container>
    <perseu-card title="Gerenciar Terapeuta">
      <v-flex slot="content">
        <filters-panel class="mb-3">
          <v-row justify="center" align="center" class="text-center">
            <v-col cols="12" md="3" lg="2">
              <v-switch
                v-model="query.formedByParmagnetico"
                label="Formado pelo IPM"
                hide-details
              />
            </v-col>

            <v-col cols="12" md="6" lg="4">
              <v-text-field
                hide-details
                v-model="query.therapistName"
                filled
                label="Nome do terapeuta"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-btn large color="secondary" @click="list"> Buscar </v-btn>
            </v-col>
          </v-row>
        </filters-panel>
        <v-data-table
          :items="therapists"
          :headers="headers"
          class="elevation-1"
        >
          <template v-slot:item.formedByParmagnetico="{ item }">
            <span>{{ item.formedByParmagnetico | booleanToString }}</span>
          </template>
          <template #item.actions="props">
            <v-btn small icon @click="edit(props.item)" color="secondary">
              <v-icon small>edit</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
  </v-container>
</template>

<script>
import { find } from "@/services/therapists-management-service";
import { pagesNames } from "@/router/pages-names";

export default {
  data: () => ({
    headers: [
      { text: "Nome do terapeuta", value: "therapistName" },
      { text: "Formado no Institudo", value: "formedByParmagnetico" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    query: {
      formedByParmagnetico: true,
      therapistName: "",
    },
    therapists: [],
  }),
  created() {
    this.list();
  },
  methods: {
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await find(this.query);
        this.therapists = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    edit({ id }) {
      this.$router.push({
        name: pagesNames.MANAGE_THERAPISTS_FORM,
        query: { id },
      });
    },
  },
};
</script>
